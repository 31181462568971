@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@import 'fontello';

@import 'slick';



@font-face {
  font-family: 'vanitas';
  src: url('../font/Vanitas-Bold/vanitas.woff') format('woff2'),
       url('../font/Vanitas-Bold/vanitas.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;



body {
   overflow-x: hidden;
}




/*------------------
GENERAL
--------------------*/

.alt {
   font-family: 'vanitas';
}

.grid-container.small-full {
   @media only screen and (max-width: 40em) {
      padding: 0;
   }
}

.grid-container.full {
   padding: 0 2rem;
   @media only screen and (min-width: 40em) {
      padding: 0;
   }
   @media only screen and (min-width: 84em) {
      max-width: 84em;
   }
}

.overlay {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background: $black;
   opacity: 40%;
   z-index: 1;
}

.block {
   margin: 60px 0;

   @media only screen and (min-width: 40em) {
      margin: 90px 0;
   }
}



.block-title {
   margin-bottom: 20px;
   .lead {
      margin-bottom: 30px;
   }
}


.image-title {
   padding: 2rem 2rem;
   margin-bottom: 30px;
   position: relative;
   h1 {
      color: white;
      position: relative;
      z-index: 2;
   }
   @media only screen and (min-width: 40em) {
      padding: 6rem 0;
   }
}


blockquote {
   font-family: 'Vanitas', serif;
   font-size: 40px;
   line-height: 1;
   margin: 30px 0;
   padding-left: 30px;

   @media only screen and (min-width: 40em) {
      font-size: 80px;
      line-height: 1;
      margin: 70px 0;
   }
}
.dienst-section {
   margin-bottom: 30px;
   @media only screen and (min-width: 40em) {
      margin-bottom: 70px;
   }
}


/*------------------
BUTTONS
--------------------*/

.button {
   text-transform: uppercase;

   &:hover {
      background: white;;
      color: $black;
   }

   &.white {
      background: white;
      color: $black;
      &:hover {
         background: $black;
         color: white;
      }
   }
   &.beige {
      background: $beige;
      color: $black;
      &:hover {
         background: $black;
         color: $beige;
      }
   }
}


.button.hollow {
   background: transparent;
   border: 1px solid $black;
   &.white {
      border: 1px solid white;
      color: white;

      &:hover {
         background: white;
         color: $black;
      }
   }

   &:hover {
      background: $black;
      color: white;
   }
}

.button {
   .icon-arrow-right-long {
      font-size: 9px;
      transition: margin .25s ease-out;
      margin-left: 10px;
      &:before {
         width: inherit;
         margin-top: -7px;
      }
   }
}


/*------------------
TOPBAR
--------------------*/

.topbar {
   background: $beige;
   padding: 16px 0;
   position: relative;

   .menu {
      position: relative;
      z-index: 10;
      padding: 16px 0;

      a {
         text-transform: uppercase;
         font-weight: bold;
         font-size: 14px;
         letter-spacing: .5px;
         &:hover {
            background: white;
         }
      }

      &:after {
         position: absolute;
         content: '';
         background: $beige;
         left: -40px;
         right: -600px;
         top: 0;
         height: 100%;
         z-index: -1;
      }
   }

   @media only screen and (min-width: 40em) {
      background: white;
      padding: 0;

      .logo {
      }
   }

}


.menuicon{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 24px;
    cursor: pointer;
    display: block;
    float: right;
    z-index: 20;
    float: right;
    margin-top: 4px;

    span{
        width: 100%;
        height: 3px;
        background: black;
        display: block;
        position: absolute;
        transition: all .5s ease-in-out;
    }
    span:nth-child(1){
        top: 0;
    }
    span:nth-child(2){
        top: 10px;
        transition: all .5s ease-in-out;
    }
    span:nth-child(3){
        top: 20px;
    }
}


.open.menuicon span:nth-child(1){
    top: 7px;
    transform: rotate(225deg);
    width: 30px;
}
.open.menuicon span:nth-child(2){
    transform: rotate(500deg);
    opacity: 0;
}
.open.menuicon span:nth-child(3){
    top: 7px;
    transform: rotate(-225deg);
    width: 30px;
}



.off-canvas {
   background: $black;
   padding: 2rem;

   ul a {
      color: $beige;
      padding-left: 0;
   }
   p {
      color: white;
   }

}


/*------------------
COVER
--------------------*/

.cover {
   position: relative;
   padding: 6rem 0 0 0;
   &.front {
      padding: 0;
   }
   h1 {
      margin-bottom: 30px;
   }
   .inner {
      position: relative;
      z-index: 2;
   }
   .image {
      height: 80vh;
      width: 100%;
      @media only screen and (min-width: 40em) {
         height: 400px;
      }
   }
   .logo {
      position: absolute;
      pointer-events: none;
      top: 50%;
      left: 50%;
      margin-top: -45px;
      margin-left: -90px;
      z-index: 10;
      width: 180px;
      @media only screen and (min-width: 40em) {
         width: 250px;
         margin-top: -105px;
         margin-left: -125px;
      }
   }
   .logo-alt {
      width: 150px;
      margin-bottom: 40px;
   }
}

/*------------------
CALLOUT
--------------------*/

.callout {

   .square {
      position: absolute;
      bottom: -15px;
      left: 50%;
      margin-left: -15px;
      width: 30px;
      height: 30px;
      transform: rotate(45deg);
      background: $beige;
      z-index: 20;
   }
   &.black .square {
      background: $black;
   }
   &.gray .square {
      background: $medium-gray;
   }

   .inner {
      p {
         margin-bottom: 40px;
      }
   }

   .hider {
      opacity: 0;
      transition: .5s ease;
      overflow: hidden;
      max-height: 0;
      transform: translateY(0);
	   -webkit-transition: max-height transform 0.8s ease;
	   -moz-transition: max-height transform 0.8s ease;
   	transition: max-height transform 0.8s ease;

      ul {
         margin-bottom: 40px;
         transition: .8s ease;
         transform: translateY(-20px);
      }
   }

   &:hover .hider {
      max-height: 300px;
      opacity: 1;

      ul {
         transform: translateY(0);
      }
   }

}


/*------------------
CARD
--------------------*/

.card {
   &:hover {
      background: $light-gray;
   }
   .button:hover {
      .icon-arrow-right-long {
         margin-left: 25px;
      }
   }
   p {
      margin-bottom: 30px;
   }
}


/*------------------
TEAM MEMBERS
--------------------*/

.team-members {
   .inner {
      margin-bottom: 60px;
      .image {
         width: 100%;
         height: 300px;
         margin-bottom: 30px;
      }
      h3 {
         margin-bottom: 0;
      }

      .function {
         font-size: 15px;
         font-weight: bold;
         margin-bottom: 20px;
      }
   }
}

/*------------------
IMAGE-BLOCK
--------------------*/

.image-block {
   background: $beige;
   position: relative;
   padding-bottom: 4rem;
   .inner {
      padding-top: 50px;
      padding-right: 0;
   }
   .cell {
      position: relative;
   }
   .image {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      min-height: 300px;
      z-index: 2;
   }
   &:after {
      content: '';
      position: absolute;;
      top:  -30px;
      left: 0;
      right: 100px;
      height: 300px;
      background: $black;
      z-index: 1;
   }
   @media only screen and (min-width: 40em) {
      padding-bottom: 6rem;
      .image {position: absolute;}
      .inner {
         padding-top: 100px;
         padding-right: 100px;
      }
      &:after {top: 0; right: 70%; bottom: 0; height: 100%;}
   }
}

/*------------------
IMAGE-QUOTE
--------------------*/

.image-quote {
   .text {
      padding: 6rem 0;
      font-size: 34px;
      line-height: 1;
      color: white;
      @media only screen and (min-width: 40em) {
         font-size: 70px;
      }
   }

}


/*------------------
IMAGE-CARD
--------------------*/


.image-card {
   position: relative;

   .inner {
      padding: 4rem 0rem 4rem 0rem;
      position: relative;
      z-index: 10;
      @media only screen and (min-width: 40em) {
         padding: 6rem 3rem 6rem 0rem;
      }

      .button {
         margin-top: 50px;
      }
   }
   .background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      .left {
         position: absolute;
         background: $beige;
         top:0;
         right: 0;
         bottom: 0;
         width: 100%;
         @media only screen and (min-width: 40em) {
            right: 50%;
         }
      }
      .right {
         position: absolute;
         top:0;
         left: 50%;
         bottom: 0;
         width: 50%;
      }
      .logo {
         width: 150px;
         position: absolute;
         left: 50%;
         top: 50%;
         margin-left: -75px;
         margin-top: -75px;
      }
   }
}

/*------------------
CONTENT
--------------------*/

.content {
   p:not(:last-child) {
      margin-bottom: 30px;
   }
   img {
      margin: 0px 0 30px 0;
   }
   @media only screen and (min-width: 40em) {
      img {
         margin: 30px 0 60px 0;
         padding-right: 200px;
      }
      p, h1, h2, h3, h4, h5, ul, ol, img {
         margin-right: 100px;
      }
   }
   @media only screen and (min-width: 64em) {
      p, h1, h2, h3, h4, h5, ul, ol, img {
         margin-right: 200px;
      }
   }
}

/*------------------
CALL-TO-ACTION
--------------------*/

.cta {
   background: $black;
   padding: 2rem 0;
   position: relative;

   &:after {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      left: 70%;
      background: $black;
   }

   .button, .lead {
      margin-bottom: 0;
      position: relative;
      z-index: 2;
   }
   .lead {
      color: white;
      margin-bottom: 10px;
   }
   .button {
      margin-right: 1rem;
   }
   @media only screen and (min-width: 40em) {
      background: $medium-gray;
      .lead {
         color: $black;
         margin-bottom: 0;
      }
      &:after {
         content: '';
      }
   }
}

.cta.secondary {
   &:after {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      left: 50%;
      background: $black;
      z-index: 1;
      opacity: 0;
   }

   .lead {margin-bottom: 20px;}
   @media only screen and (min-width: 64em) {
      .lead {margin-bottom: 0;}
      &:after { opacity: 1;}
      .button:first-child {
         margin-left: 30px;
      }
   }

}


/*------------------
RECENSIE SLIDER
--------------------*/

.recensie-slider {
   margin-bottom: 4rem;
   .inner {
      background: $beige;
      height: 100%;
      padding: 2rem;
   }
   .image {
      position: relative;
      height: 200px;
   }

   @media only screen and (min-width: 40em) {
      margin-bottom: 8rem;

      .inner {
         padding: 3rem;
      }
      .image {
         height: 100%;
         width: 300px;
      }
   }

}




/*------------------
FOOTER
--------------------*/
.footer {
   overflow-x: hidden;

   .left {
      padding: 4rem 2rem 4rem 0 ;
      background: $black;
      position: relative;
      height: 100%;

      &:before {
         background: #000037;
         position: absolute;
         left: -900px;
         top: 0;
         bottom: 0;
         right: 0;
         content: '';
         z-index: -1;
      }
      ul  a {
         padding: .5rem 1rem .5rem 0;
      }
      ul a, h3 {
         color: white;
      }
      h3 {
         text-transform: uppercase;
      }

      .trees-for-all {
         margin-top: 3rem;
         img {
            width: 45px;
            margin-right: 20px;
         }
         p {
            color: white;
            font-size: 12px;
            margin-bottom: 0;
         }
      }

   }

   .right {
      background: $medium-gray;
      padding: 4rem 0 4rem 4rem;
      height: 100%;
      position: relative;

      h3 {
         text-transform: uppercase;
      }

      &:after {
         background: $medium-gray;
         position: absolute;
         right: -900px;
         top: 0;
         bottom: 0;
         left: 0;
         content: '';
         z-index: -1;
      }
   }
}

.bottom-footer {
   position: relative;
   padding: 20px 0;
   p {
      margin: 0;
      font-weight: bold;
      font-size: 14px;
   }

   &:after {
      background: $beige;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 70%;
   }
}



/*------------------
SLICK SETTINGS
--------------------*/


.slick-dots {
   position: absolute;
   margin-left: 0;
   list-style-type: none;
   text-align: center;
   width: 100%;
   bottom: 20px;

   li {
      position: relative;
       display: inline-block;
       margin: 0 5px;
       padding: 0;
       cursor: pointer;
      button {
         font-size: 0;
          line-height: 0;
          display: block;
          width: 10px;
          height: 10px;
          cursor: pointer;
          border: 1px solid white;
          border-radius: 50%;
          background: transparent;
      }
      &.slick-active {
         button {
            border: 1px solid white;
            background: white;
         }
      }
      &:hover button {
         background: white;
      }
   }
}

.recensie-slider .slick-dots {
   bottom: -50px;
   li {
      button {
         border: 1px solid $black;
      }
      &.slick-active {
         button {
            border: 1px solid $black;
            background: $black;
         }
      }
   }
}
